import React, { useRef, useState, useContext } from 'react'
import './contact.scss'
import emailjs from "@emailjs/browser";
import { themeContext } from "../../Context";
// import { FaFacebookF, FaTwitter, FaLinkedinIn, FaGithub } from "react-icons/fa";

const Contactemail = () => {

    const theme = useContext(themeContext);
    //const darkMode = theme.state.darkMode;
    const form = useRef();
    const [done, setDone] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_2mu5xtl",
                "template_m5udu2c",
                form.current,
                "VLwg1ltOWvnCYAiK_"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setDone(true);
                    form.reset();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    /////////////////////

    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    // ========== Email Validation start here ==============
    const emailValidation = () => {
        return String(email)
            .toLocaleLowerCase()
            .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
    };
    // ========== Email Validation end here ================



    const handleSend = (e) => {
        e.preventDefault()
        //console.log("hello")

        if (name === "") {
            setErrMsg("Name is required!");
            console.log("is required")
        } else if (number === "") {
            setErrMsg("Phone number is required!");
        } else if (email === "") {
            setErrMsg("Please give your Email!");
        } else if (!emailValidation(email)) {
            setErrMsg("Give a valid Email!");
        } else if (subject === "") {
            setErrMsg("Please give your Subject!");
        } else if (message === "") {
            setErrMsg("Message is required!");
        } else {
            // setSuccessMsg(
            //     ` Your Messages has been sent Successfully! You can also connect with me on LinkedIn`
            // );
            // setErrMsg("");
            // setName("");
            // setNumber("");
            // setEmail("");
            // setSubject("");
            // setMessage("");
            // console.log(name, number, email, message, subject)
            emailjs
                .sendForm(
                    "service_zzvmdft",
                    "template_d17b4ar",
                    form.current,
                    "BhbFYBEyttMKoDykP"
                )
                .then(
                    (result) => {
                        console.log(result.text);
                        setDone(true);
                        setSuccessMsg(
                            `Your Messages has been sent Successfully! `
                        );
                        setErrMsg("");
                        setName("");
                        setNumber("");
                        setEmail("");
                        setSubject("");
                        setMessage("");
                        form.current.reset();
                    },
                    (error) => {
                        console.log(error.text);
                        setErrMsg("Something went wrong while sending your message!");
                    }
                );

        }

    }
    return (
        <div className="contact-form" id="contact">
            {/* left side copy and paste from work section */}
            <div className="w-left">
                <div className="awesome">
                    {/* darkMode */}
                    {/* <span style={{ color: darkMode ? 'white' : '' }}>Get in Touch</span> */}
                    <span className="first">Get in </span><br />
                    <span className="first"> Touch With Me</span>
                    <div className="infor ">
                        <h2>Find Me on</h2>
                        <div className="icon">
                            {/* <span className="bannerIcon">
                                <a href="https://twitter.com/kamskanagi" target="_blank"> <FaTwitter /> </a>
                            </span>
                            <span className="bannerIcon">
                                <a href="https://linkedin.com/in/karamo-kanagi-ph-d-14546526/" target="_blank"> <FaLinkedinIn /> </a>
                            </span>
                            <span className="bannerIcon" >
                                <a href="https://github.com/kamskanagi" target="_blank"><FaGithub /></a>
                            </span> */}
                        </div>
                    </div>


                </div>
            </div>
            {/* right side form */}
            <div className="c-right">
                <form ref={form} onSubmit={sendEmail} className="infoForm authForm">
                    {errMsg && (
                        <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                            {errMsg}
                        </p>
                    )}
                    {successMsg && (
                        <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                            {successMsg}
                        </p>
                    )}
                    <div>
                        <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)}
                            className="user" placeholder="Name" />

                        <input type="number" name="number" onChange={(e) => setNumber(e.target.value)} value={number}
                            className="user" placeholder=" Number" />
                    </div>

                    <div>
                        <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email}
                            className="user" placeholder="Email" />
                    </div>

                    <div>
                        <input type="text" name="subject" onChange={(e) => setSubject(e.target.value)} value={subject}
                            className="user" placeholder="Subject" />
                    </div>

                    <div>
                        <textarea name="message" onChange={(e) => setMessage(e.target.value)} value={message}
                            className="user" placeholder="Message" />
                    </div>

                    <div>
                        <button className="button" onClick={handleSend}>Send Message </button>
                    </div>
                    {/* <span>{done && "Thanks for Contacting me"}</span> */}

                </form>
            </div>
        </div>
    )
}

export default Contactemail
