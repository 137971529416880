import React from 'react'
import "./banner.scss";
const Banner = () => {
    return (
        <div className='banner'>
            <div className="wrapper">
                <span>Why Us</span>

            </div>
            <div className="icons">
                <img src="img/us.png" alt="" />
            </div>

        </div>
    )
}

export default Banner
