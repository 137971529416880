import React from 'react'
import './categories.scss'
import { Link } from 'react-router-dom'

const Categories = () => {
  return (
    <div className='categories'>

      <div className="col">
        <div className="row">
          <img src="https://images.pexels.com/photos/2882234/pexels-photo-2882234.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
          <button><Link to='/product/1' className="link" >Off-Road and 4x4 Vehicles </Link></button>
        </div>
        <div className="row">
          <img src="https://images.pexels.com/photos/3849551/pexels-photo-3849551.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
          <button><Link to='/product/1' className="link" >Luxury Car </Link></button>
        </div>
      </div>

      <div className="col">
        <div className="row">
          <img src="https://images.pexels.com/photos/9800006/pexels-photo-9800006.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
          <button><Link to='/product/1' className="link" >Electric Car</Link></button>
        </div>
      </div>

      <div className="col col-l">
        <div className="row">
          <div className="col">
            <div className="row">
              <img src="https://images.pexels.com/photos/2365572/pexels-photo-2365572.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
              <button><Link to='/product/1' className="link" >Sports Car </Link></button>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <img src="https://images.pexels.com/photos/3846205/pexels-photo-3846205.jpeg?auto=compress&cs=tinysrgb&w=1600" alt="" />
              <button><Link to='/product/1' className="link" >Plug-in Hybrid (PHEV)</Link></button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row"><img src="https://media.istockphoto.com/id/1140988145/photo/commercial-delivery-vans-in-row.jpg?b=1&s=612x612&w=0&k=20&c=fF84QJ8WTIwoqqx7b9_Silb63wVVuW3go3rhwmdXwjE=" alt="" />
            <button><Link to='/product/1' className="link" >Minivan</Link></button></div>
        </div>
      </div>
    </div >
  )
}

export default Categories
