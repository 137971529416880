import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react'
import './list.scss'
import Cards from '../Cards/Cards';
import axios, * as others from 'axios';

const List = ({ subCats, maxPrice, sort, catId }) => {

  
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(process.env.REACT_APP_API_URL + `/products?populate=*&[filters][categories][id][$eq]=${catId}${subCats.map(
          (item) => `&[filters][subcategories][id][$eq]=${item}`
        )}&[filters][price][$lte]=${maxPrice}`, {
          // )}&[filters][price][$lte]=${Maxprice}&sort=price:${Sort}`, {
          
         // const res = await axios.get(process.env.REACT_APP_API_URL+"/products", {
          headers:{
            Authorization: "bearer "+ process.env.REACT_APP_API_TOKEN, 
          }
        })

        console.log(res.data.data)
        setData(res.data.data)
        
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }

    fetchData()
  }, [])


    // const data = [
    //     {
    //      id:1,
    //      img:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //      img2:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //      title:"first images",
    //      isNew:true,
    //      oldPrice: 19,
    //      price: 12,
    //     },
    //     {
    //      id:2,
    //      img:"https://images.pexels.com/photos/949670/pexels-photo-949670.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //      img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //      title:"second images",
    //      oldPrice: 19, 
    //      isNew:true,
    //      price: 12,
    //     },
    //     {
    //      id:3,
    //      img:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //      img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //      title:"third images",
    //      isNew:false,
    //      oldPrice: 19,
    //      price:12,
    //     },
    //     {
    //         id:4,
    //         img:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //         img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //         title:"third images",
    //         isNew:false,
    //         oldPrice: 19,
    //         price:12,
    //        },
    //        {
    //         id:5,
    //         img:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //         img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //         title:"third images",
    //         isNew:false,
    //         oldPrice: 19,
    //         price:12,
    //        },
    //        {
    //         id:6,
    //         img:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //         img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
    //         title:"third images",
    //         isNew:false,
    //         oldPrice: 19,
    //         price:12,
    //        },
     
    //    ];
     
     
  return (
    <div className='list'>
      {
        loading? "loading":data?.map((item) => 
        <Cards item={item} key={item.id}/>
      )}
    </div>
  )
}

export default List
