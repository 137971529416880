import React, { useEffect, useState } from 'react'
import './feature.scss'
import Cards from '../Cards/Cards'
// import axios, * as others from 'axios';
import axios from 'axios';
import useFetch from '../../hooks/useFetch';

const FeatureProducts = ({ type }) => {

  // const { data, loading, error } = useFetch(
  //   `/products?populate=*&[filters][type][$eq]=${type}`
  // );

  // const data = [
  //  {
  //   id:1,
  //   img:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //   img2:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //   title:"first images",
  //   isNew:true,
  //   oldPrice: 19,
  //   price: 12,
  //  },
  //  {
  //   id:2,
  //   img:"https://images.pexels.com/photos/949670/pexels-photo-949670.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //   img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //   title:"second images",
  //   oldPrice: 19, 
  //   isNew:true,
  //   price: 12,
  //  },
  //  {
  //   id:3,
  //   img:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //   img2:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //   title:"third images",
  //   isNew:false,
  //   oldPrice: 19,
  //   price:12,
  //  },

  // ];

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(process.env.REACT_APP_API_URL + `/products?populate=*&filters[type][$eq]=${type}`, {
          // const res = await axios.get(process.env.REACT_APP_API_URL+"/products", {
          headers: {
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          }
        })

        console.log(res.data.data)
        setData(res.data.data)

      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }

    fetchData()
  }, [type])


  //console.log(data)

  // const { data, loading, error } = useFetch(
  //   `/products?populate=*&[filters][type][$eq]=${type}`
  //   );

  return (
    <div className='featureproducts'>
      <div className="top">
        <h1>{type} Products</h1>
        <p>
          Every month, we handpick a selection of
          cars that stand out in terms of value, performance, and customer satisfaction.
          This is your go-to spot for finding exclusive deals, limited-time offers,
          and the latest models that we believe offer the best experience and value for our customers.
        </p>
      </div>


      <div className="bottom">
        {/* {data.map((item)=>(
            <Cards item={item} key={item.id}/>
          ))} */}

        {error
          ? "Something went wrong!"
          : loading
            ? "loading"
            : data?.map((item) => <Cards item={item} key={item.id} />)}
      </div>

    </div>
  )
}

export default FeatureProducts
