import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";
import './Navbar.scss'
import Cart from "../Cart/Cart";
import { useSelector } from 'react-redux';

const Navbar = () => {
    const products = useSelector((state) => state.cart.products);
    const [open, setOpen] = useState(false)
    return (
        <div className='navbar'>
            <div className="wrapper">
                <div className="left">

                    {/* 
                    
                    <div className="item">
                        <img src="img/en.png" alt="" />
                        <KeyboardArrowDownIcon />
                    </div>
                    
                    <div className="item">
                        <span>USD</span>
                        <KeyboardArrowDownIcon />
                    </div> */}
                    <div className="item">
                        <Link className="link" to="/products/4"> Products</Link>
                    </div>
                    <div className="item">
                        <Link className="link" to="/products/2">Services</Link>
                    </div>
                    {/* <div className="item">
                        <Link className="link" to="/products/2">Children</Link>
                    </div> */}
                </div>

                <div className="center">
                    <Link className="link" id='logo' to="/">(M.A.M) Store</Link>
                </div>


                <div className="right">
                    <div className="item">
                        <Link className="link" to="/">Home</Link>
                    </div>
                    <div className="item">
                        <Link className="link" to="/about">About</Link>
                    </div>
                    <div className="item">
                        <Link className="link" to="/contact">Contact</Link>
                    </div>
                    {/* <div className="item">
                    <Link className="link" to="/"></Link>
                </div> */}
                    {/* <div className="icons">
                        <SearchIcon />
                        <PersonOutlineOutlinedIcon />
                        <FavoriteBorderOutlinedIcon />

                        <div className="cartIcon" onClick={() => setOpen(!open)}>
                            <ShoppingCartOutlinedIcon />
                            <span>{products.length}</span>
                        </div>
                    </div> */}

                </div>

            </div>
            {open && <Cart />}
        </div>
    )
}

export default Navbar
