import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import Product from "./pages/Product/Product";
import Contactemail from "./pages/ContactEmail/Contactemail";
import About from "./pages/About/About";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import './app.scss'

const Layout = () => {
  return (
    <div className="app">
      <Navbar />
      {/*  // this represent out different pages  */}
      <Outlet />
      <Footer />
    </div>
  )

}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/products/:id",
        element: <Products />,
      },
      {
        path: "/product/:id",
        element: <Product />,
      },
      {
        path: "/contact",
        element: <Contactemail />,
      },
      {
        path: "/about",
        element: <About />,
      },
    ]


  },
  {
    path: "/products/:id",
    element: <Products />,
  },
  {
    path: "/product/:id",
    element: <Product />,
  },

  {
    path: "/",
    element: <span>Home</span>,
  },
]);


function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
