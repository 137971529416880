import React, { useState, useEffect } from 'react';
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import './slider.scss';

const Slider = () => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const data = [
        "https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=1600",
        "https://images.pexels.com/photos/2036544/pexels-photo-2036544.jpeg?auto=compress&cs=tinysrgb&w=1600",
        "https://images.pexels.com/photos/2920064/pexels-photo-2920064.jpeg?auto=compress&cs=tinysrgb&w=1600",
    ];
    const nextSlide = () => {
        if (currentSlide === data.length - 1) {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentSlide(0);
                setIsTransitioning(false);
            }, 50); // short delay to set state without transition
        } else {
            setCurrentSlide(prev => prev + 1);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            nextSlide();
        }, 3000);

        return () => clearTimeout(timer);
    }, [currentSlide]);

    return (
        <div className='slider'>
            <div className="container" style={{
                transform: `translateX(-${currentSlide * 100}vw)`,
                transition: isTransitioning ? 'none' : 'all 0.3s'
            }}>
                {data.map((imgSrc, index) => (
                    <img key={index} src={imgSrc} alt="" />
                ))}
            </div>
            <div className="icons">
                <div className="icon" onClick={nextSlide}>
                    <WestOutlinedIcon />
                </div>
                <div className="icon" onClick={nextSlide}>
                    <EastOutlinedIcon />
                </div>
            </div>
        </div>
    )

}

export default Slider;
