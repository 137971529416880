import React, { useState, useEffect } from 'react'
import './product.scss'
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BalanceIcon from "@mui/icons-material/Balance";
import { setRef } from '@mui/material';
import { useParams } from 'react-router-dom';
// import axios, * as others from 'axios';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { addToCart } from '../../redux/cartReducer';

const Product = () => {

  const [selectedImage, setSelectedImage] = useState("img")
  const [quantity, setQuantity] = useState(1)
  const dispatch = useDispatch();
  //   const images = [
  //       "https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
  //       "https://images.pexels.com/photos/949670/pexels-photo-949670.jpeg?auto=compress&cs=tinysrgb&w=1600",

  // ];

  const id = parseInt(useParams().id)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(process.env.REACT_APP_API_URL + `/products/${id}?populate=*`, {
          headers: {
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          }
        })

        console.log("here" + res)
        setData(res.data.data)
        //console.log("Response data:", res.data);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }

    fetchData()
  }, [id])

  //console.log(data)

  return (
    <div className='product'>
      {loading ? ("loading") : (
        <>

          <div className="left">
            <div className="images">
              <img src={process.env.REACT_APP_UPLOAD_URL + data?.attributes?.img?.data?.attributes?.url} alt="" onClick={(e) => setSelectedImage("img")} />
              <img src={process.env.REACT_APP_UPLOAD_URL + data?.attributes?.img2?.data?.attributes?.url} alt="" onClick={(e) => setSelectedImage("img2")} />
            </div>
            <div className="mainImg">
              <img src={process.env.REACT_APP_UPLOAD_URL + data?.attributes?.[selectedImage]?.data?.attributes?.url} alt="" />
            </div>
          </div>

          <div className="right">

            <h1>{data?.attributes?.title}</h1>
            <span className='price'>{data?.attributes?.price}</span>
            <p> {data?.attributes?.desc}</p>
            <div className="quantity">
              <button onClick={() => setQuantity((prev) => prev === 1 ? 1 : prev - 1)}>-</button>
              {quantity}
              <button onClick={() => setQuantity((next) => next + 1)}>+</button>
            </div>

            <div className="add" onClick={() => dispatch(addToCart({
              id: data.attributes.id,
              title: data.attributes.title,
              desc: data.attributes.desc,
              price: data.attributes.price,
              img: data.attributes.img.data.attributes.url,
              quantity,

            }))}>
              <button> < AddShoppingCartIcon /> Add to Cart</button>
            </div>
            <div className="links">
              <div className="item"> < FavoriteBorderIcon /> add to watchlist</div>
              <div className="item">< BalanceIcon /> add to compare</div>
            </div>
            <div className="info">
              <span>Vendor: Polo</span>
              <span>Product Type: T-Shirt</span>
              <span>Tag: T-Shirt, Women, Top</span>
            </div>
            <hr />
            <div className="info">
              <span>DESCRIPTION</span>
              <hr />
              <span>ADDITIONAL INFORMATION</span>
              <hr />
              <span>FAQ</span>
            </div>
          </div>
        </>
      )
      }

    </div>
  )
}

export default Product
