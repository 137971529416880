import React from 'react'
import './footer.scss'

const Footter = () => {
  return (
    <div className='footer'>
      <div className="top">
        <div className="item">
          <h1>Categories</h1>
          <span>4X4 Vehicles</span>
          <span>Sport Cars</span>
          <span>Luxury Cars</span>
          <span>Minvans</span>
          {/* <span>New Arrivals</span> */}
        </div>
        <div className="item">
          <h1>Serices</h1>
          <span>FAQ</span>
          <span>About</span>
          <span>Products</span>
          <span>Compare</span>
          <span>Cookies</span>
        </div>
        <div className="item">
          <h1>About</h1>
          <span>
            At M.A.M Store, we connect you with the best car buying and leasing opportunities.
            Whether you're looking for a family car, a sports car, or an eco-friendly vehicle,
            we've got you covered
          </span>
        </div>
        <div className="item">
          <h1>Contact</h1>
          <span>
            You can connect social media such as Facebook, Whatsapp, Instagram and Tiktok.
          </span>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <span className="logo">M.A.M Store</span>
          <span className="copyright">
            © Copyright 2023. All Rights Reserved
          </span>
        </div>
        <div className="right">
          <img src="/img/payment.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Footter
