import React from 'react'
import './cart.scss'
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from 'react-redux';
import { removeItem, resetCart } from '../../redux/cartReducer';
import { useDispatch } from 'react-redux'


const Cart = () => {
    const products = useSelector((state) => state.cart.products);
    const dispatch = useDispatch();
    const data = [
        {
         id:1,
         img:"https://images.pexels.com/photos/1549200/pexels-photo-1549200.jpeg?auto=compress&cs=tinysrgb&w=1600",
         img2:"https://images.pexels.com/photos/837140/pexels-photo-837140.jpeg?auto=compress&cs=tinysrgb&w=1600",
         title:"first images",
         desc:"first images",
         isNew:true,
         oldPrice: 19,
         price: 12,
        }
    ]
  const totalPrice = () => {
    let total = 0
    products.forEach((item) => (total += item.quantity * item.price))
    return total.toFixed(2)
}
  return (
    <div className='cart'>
      <h1>Product in your cart</h1>
      {products.map((item)=>(
        <div className="item">
            <img src={process.env.REACT_APP_UPLOAD_URL +item.img} alt="" />
            <div className="details">
                <h1>{item.title}</h1>
                <p>{item.desc.substring(0, 100)}</p>
                <div className="price">{item.quantity} * {item.price}</div>
            </div>
            <DeleteOutlinedIcon  className="delete" onClick={()=>dispatch(removeItem(item.id))} />
        </div>
      ))}
      <div className="total">
        <span>SUBTOTAL</span>
        <span>${totalPrice()}</span>
      </div>
      <button >PROCEED TO CHECKOUT</button>
      <span className="reset" onClick={()=>dispatch(resetCart())} >
        Reset Cart
      </span>
        
    </div>
  )
}

export default Cart
