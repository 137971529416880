import React from 'react'
// import Slider from '../../components/Slider/Slider'
// import FeatureProducts from '../../components/FeatureProducts/FeatureProducts'
// import Categories from '../../components/Categories/Categories'
import './about.scss'
const About = () => {
    return (
        <div className='about'>
            <h1>Welcome to Mbye's automobile and merchandise ( M.A.M )</h1>
            <p>
                At Auto Deals, we connect you with the best car buying and leasing opportunities.
                Whether you're looking for a family car, a sports car, or an eco-friendly vehicle,
                we've got you covered.
            </p>

            <section>
                <h2>Our Mission</h2>
                <p>
                    Our mission is to create an exceptional shopping experience for car enthusiasts, offer a wide range of
                    high-performance vehicles and premium merchandise that represent their passion for automotive excellence.
                    We will empower our customers with the knowledge and resources they need to make informed decisions when
                    purchasing vehicles and merchandise, and also strive to offer wide selection of high-quality cars and
                    merchandise and exceptional customer services all delivered in transparent and hassle-free manner.
                    We aim to exceed customer expectation by providing quality services, a diverse product selection, and
                    continuous innovations, while upholding the highest standard of quality integrity.
                </p>
            </section>
            <section>
                <h2>Our Vision</h2>
                <p>
                    Our mission is to create an exceptional shopping experience for car enthusiasts, offer a wide range of
                    high-performance vehicles and premium merchandise that represent their passion for automotive excellence.
                    We will empower our customers with the knowledge and resources they need to make informed decisions when
                    purchasing vehicles and merchandise, and also strive to offer wide selection of high-quality cars and
                    merchandise and exceptional customer services all delivered in transparent and hassle-free manner.
                    We aim to exceed customer expectation by providing quality services, a diverse product selection, and
                    continuous innovations, while upholding the highest standard of quality integrity.
                </p>
            </section>
            <section>
                <h2>Core Themes</h2>
                <ul>
                    <li><strong>Customer Focus:</strong> Ensuring customer satisfaction with personalized service and support.</li>
                    <li><strong>Innovation:</strong> Continuously improving and evolving our platform to meet changing market needs.</li>
                    <li><strong>Sustainability:</strong> Promoting eco-friendly vehicles and practices to contribute to a healthier planet.</li>
                </ul>
            </section>

        </div>
    )
}

export default About
